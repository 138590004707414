import * as React from "react";
import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, Spin, Button, BackTop, Timeline } from "antd";
import QRScanner from "../components/QRScanner";
import moment from "moment";
import * as Yup from "yup";
import { AuthContext } from "../components/authProvider";
import { getDownloadSignedUrl } from "../utils/s3_helper";
import anonymous_photo from "../assets/profile_image.png";

const STATUS_PATHNAME = "https://gatekeeper.indiahealth.com/immunity-status/";
const BODY_TEMPERATURE_THRESHOLD = 38;
const OXYGEN_SATURATION_THRESHOLD = 94;

export const GET_SUBJECT = gql`
query getSubject($uuid: uuid!) {
  subject(where: {uuid: {_eq: $uuid}}) {
    uuid
    name
    gender
    dob
    address
    photo
    gov_id
    covidab_status
    covidag_status
    latest_vital_signs {
      created_at
      body_temperature
      oxygen_saturation
    }
    last_test_validated_at
    pass_issue_date
    labs {
      test_results(order_by: {test_date: desc}) {
        test_date
        result
        kit {
          test_type
        }
        lab_subject {
          lab {
            lab_name
            address
            certificate_no
          }
        }
      }
    }
  }
}
`;

const INSERT_VITAL_SIGNS = gql`
  mutation insertVitalSigns($obj: vital_signs_insert_input!) {
    insert_vital_signs_one(object: $obj) {
      subject_id
    }
  }
`;

export default function Status(props) {
  const authState = React.useContext(AuthContext);
  const [testData, setTestData] = React.useState([]);
  const [photo, setPhoto] = React.useState(null);
  const [ getSubject, { loading, data: subjectData }] = useLazyQuery(GET_SUBJECT, {
    fetchPolicy: "no-cache",
    onCompleted: async(data) => {
      if (data.subject[0]) {
        let tests = [];
        data.subject[0].labs.map(lab => lab.test_results.map(t => tests.push(t)));
        // Sort in asc
        tests.sort((a,b) => a.test_date.localeCompare(b.test_date));
        console.log(tests);
        setTestData(tests);
        if (data.subject[0].photo) {
          const photoUrl = await getDownloadSignedUrl(data.subject[0].photo, {headers: {authorization: `Bearer ${authState.token}`}});
          setPhoto(photoUrl);
        }
      }
    }
  });
  const [addVitalSigns] = useMutation(INSERT_VITAL_SIGNS);

  const Status = () => {
    if (subjectData.subject[0].covidag_status === null && 
      (subjectData.subject[0].covidab_status === null || 
       subjectData.subject[0].covidab_status)) {
      //Orange
      return <span className="bg-orange-500 rounded-md p-2">Not tested</span>;
    }
    else if (subjectData.subject[0].covidag_status) {
      //Red
      return <span className="bg-red-700 rounded-md p-2">Infected</span>;
    }
    else if ((!subjectData.subject[0].covidag_status &&
              (subjectData.subject[0].covidab_status === null || 
              !subjectData.subject[0].covidab_status)) ||
            (subjectData.subject[0].covidag_status === null &&
            !subjectData.subject[0].covidab_status)) {
      //Blue
      if (subjectData.subject[0].latest_vital_signs.length &&
          (subjectData.subject[0].latest_vital_signs[0].body_temperature > BODY_TEMPERATURE_THRESHOLD || 
            subjectData.subject[0].latest_vital_signs[0].oxygen_saturation < OXYGEN_SATURATION_THRESHOLD) &&
          moment(subjectData.subject[0].latest_vital_signs[0].created_at).isAfter(moment(subjectData.subject[0].last_test_validated_at))) {
        return <span className="bg-orange-500 rounded-md p-2">Test required</span>;
      }
      else {
        document.getElementById("vital-signs-form").style.display = "block";
        return <span className="bg-blue-500 rounded-md p-2">Not infected</span>;
      }
    }
    else if (!subjectData.subject[0].covidag_status &&
            subjectData.subject[0].covidab_status) {
      //Green
      if (subjectData.subject[0].latest_vital_signs.length &&
          (subjectData.subject[0].latest_vital_signs[0].body_temperature > BODY_TEMPERATURE_THRESHOLD || 
            subjectData.subject[0].latest_vital_signs[0].oxygen_saturation < OXYGEN_SATURATION_THRESHOLD) &&
          moment(subjectData.subject[0].latest_vital_signs[0].created_at).isAfter(moment(subjectData.subject[0].last_test_validated_at))) {
        return <span className="bg-orange-500 rounded-md p-2">Test required</span>;
      }
      else {
        document.getElementById("vital-signs-form").style.display = "block";
        return <span className="bg-green-500 rounded-md p-2">Safe</span>;
      }
    }
  };

  const TestsTimeline = () => {
    return (
      <div className="p-4 content-center m-auto">
        <Timeline mode="left" className="text-xs">
          {testData.map(test => 
            <Timeline.Item label={<label>{moment(test.test_date).format("MMMM D, YYYY")}</label>}>
              <p>{test.kit.test_type === "COVIDAB" ? "AB" : "AG"} {test.result ? "Positive" : "Negative"}</p>
              <small className="capitalize text-gray-600">{test.lab_subject.lab.lab_name}, {test.lab_subject.lab.address.city}</small>
            </Timeline.Item>)}
        </Timeline>
      </div>
    );
  };

  const decimalRegExp = /^\d*\.{0,1}\d*$/;
  const VitalSignsSchema = Yup.object({
    temp: Yup.string()
      .ensure()
      .matches(decimalRegExp, "Only numeric")
      .required("Required"),
    ox_sat: Yup.string()
      .ensure()
      .matches(decimalRegExp, "Only numeric")
      .required("Required")
  });

  const onVitalSignsSubmit = async (values) => {
    if (values.temp > BODY_TEMPERATURE_THRESHOLD || values.ox_sat < OXYGEN_SATURATION_THRESHOLD) {
      document.getElementById("vital-signs-status").innerHTML = "Patron has fever or low oxygen saturation. Requires testing";
      document.getElementById("vital-signs-status").style.color = "red";
      await addVitalSigns({
        variables: {
          obj: {
            subject_id: subjectData.subject[0].uuid,
            body_temperature: values.temp,
            oxygen_saturation: values.ox_sat
          }
        }
      });
      document.getElementById("vital-signs-form").style.display = "none";
    }
    else {
      document.getElementById("vital-signs-status").innerHTML = "Patron ok";
      document.getElementById("vital-signs-form").style.display = "none";
    }
  };

  const AddVitalSigns = () => {
    return (
      <div className="m-4 p-4">
      <Formik
        initialValues={{
          ox_sat: "",
          temp: ""
        }}
        validationSchema={VitalSignsSchema}
        onSubmit={onVitalSignsSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, touched, handleChange, handleBlur, status, isSubmitting }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Body temperature</label>
              <Input
                size="large"
                type="temp"
                name="temp"
                className={errors.temp && touched.temp ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.temp}
                suffix={"Celsius"}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="temp"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Oxygen saturation (SpO2)</label>
              <Input
                size="large"
                type="text"
                name="ox_sat"
                className={
                  errors.ox_sat && touched.ox_sat ? "border-red-500" : "border-gray-400"
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ox_sat}
                suffix={"%"}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="ox_sat"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="my-4 space-y-4 md:space-x-4 text-center">
              <Button
                size="middle"
                type="primary"
                className="bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64"
                shape="round"
                block={true}
                htmlType="submit"
                loading={isSubmitting}
              >
                Check vital signs
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      </div>
    );
  }

  const PatronInfo = () => {
    return (
      (!subjectData || loading || (subjectData && subjectData.subject[0] && subjectData.subject[0].photo && !photo)) ? 
      <div className="m-auto p-2 content-center"><Spin size="large"/></div> : 
      !subjectData.subject[0] ? 
      <div className="text-center text-red-500">Unauthorised</div> : 
      <>
      <div className="md:flex p-6">
        <img 
          className="h-16 w-16 md:h-24 md:w-24 rounded-full mx-auto md:mx-0 md:mr-6" 
          alt="patron_photo" 
          src={subjectData.subject[0].photo ? photo : anonymous_photo}/>
        <div className="text-center md:text-left m-auto md:ml-0">
          <h2 className="text-lg">{subjectData.subject[0].name}</h2>
          <small className="text-gray-600">({subjectData.subject[0].gender}, {moment().diff(moment(subjectData.subject[0].dob), "years") ? 
                                        `${moment().diff(moment(subjectData.subject[0].dob), "years")} years` : 
                                        `${moment().diff(moment(subjectData.subject[0].dob), "months")} months`})</small>
          <div className="text-white text-center text-lg font-bold uppercase my-4 md:ml-0">
            <Status/>
          </div>
        </div>
      </div>
      {testData && <TestsTimeline />}
      </>
    );
  };

  const updateUrl = (url) => {
    if (url && url.includes(STATUS_PATHNAME)) {
      document.getElementById("vital-signs-status").innerHTML = "";
      document.getElementById("scan-status").innerHTML = "";
      document.getElementById("scan-result").style.display = "block";
      const subject_id = url.replace(STATUS_PATHNAME, "");
      console.log(subject_id);
      getSubject({variables: {
        uuid: subject_id
      }});
    }
    else {
      console.log("Invalid QR");
      document.getElementById("scan-status").innerHTML = "Invalid QR";
      document.getElementById("scan-result").style.display = "none";
    }
  };

  return (
    <div className="p-8 md:p-16 mx-auto">
      <h2 className="text-lg md:text-xl text-center text-teal-700 mb-4">Scan to check status</h2>
      <QRScanner updateUrl={updateUrl} style={{height: 150, width: 150, margin: 'auto', align: 'center'}}/>
      <p className="text-red-500 text-center text-md mt-2" id="scan-status"></p>
      <div id="scan-result" style={{display:"none"}} className="w-11/12 mt-4 pb-4 mx-auto bg-gray-100 shadow-md overflow-hidden rounded-lg">
      {/* <div id="scan-result" style={{display:"none"}} className="w-11/12 mt-4 pb-4 mx-auto"> */}
        <PatronInfo/>
        <p className="text-center text-md font-semibold text-gray-800 mt-2" id="vital-signs-status"></p>
        <div id="vital-signs-form" style={{display: "none"}}>
          <AddVitalSigns />
        </div>
      </div>
      <BackTop />
    </div>
  );
}