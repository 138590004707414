import * as React from "react";
import gql from "graphql-tag";
import { useQuery} from "@apollo/react-hooks";
import { Spin, Button, BackTop } from "antd";
import moment from "moment";

export const GET_USER_DETAILS = gql`
  query getUserDetails {
    user {
      name
      email
      profile_completed
      organisation {
        uuid
        org_name
        tax_id
        phone
        address
        email
        created_at
      }
    }
  }
`;

export default function Profile(props) {
  const { loading, error, data: userData } = useQuery(GET_USER_DETAILS);

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 md:p-16 mx-auto">
      <h2 className="text-lg md:text-xl text-center text-teal-700 mb-4">Organisation profile</h2>

      <div className="space-y-4">
        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">ID </p>{" "}
          <p>{userData.user[0].organisation.uuid}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Member since </p>{" "}
          <p>{moment(userData.user[0].organisation.created_at).format("MMMM D, YYYY")}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Organisation name </p>{" "}
          <p className="capitalize">{userData.user[0].organisation.org_name}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">GSTN/PAN </p>{" "}
          <p>{userData.user[0].organisation.tax_id}</p>
        </div>
        
        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Contact name </p>{" "}
          <p className="capitalize">{userData.user[0].name}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Email </p>{" "}
          <p>{userData.user[0].organisation.email}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Phone </p>{" "}
          <p>+91{userData.user[0].organisation.phone}</p>
        </div>

        <div className="flex space-x-1 text-gray-700">
          <p className="font-semibold">Address </p>{" "}
          <p>
            {userData.user[0].organisation.address.line1}{" "}{userData.user[0].organisation.address.line2}{" "}
            {userData.user[0].organisation.address.city}{" "}{userData.user[0].organisation.address.state}{" "}-{" "}
            {userData.user[0].organisation.address.pincode}
          </p>
        </div>

        <Button
          size="middle"
          type="primary"
          className="bg-purple-700 hover:bg-purple-600 border-none h-10"
          shape="round"
          onClick={() => props.history.push("/edit-org-profile")}
        >
          Edit organisation profile
        </Button>
      </div>
      <BackTop />
    </div>
  );
}
