import React from "react";
import QrReader from "react-qr-reader";
import { withRouter } from "react-router-dom";
import { Button } from "antd";

export const QRScanner = withRouter(props => <QRScannerComponent {...props}/>);

class QRScannerComponent extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      error: null,
      legacyMode: false
    }
    this.handleScan = this.handleScan.bind(this)
    this.openImageDialog = this.openImageDialog.bind(this)
  }

  handleScan = data => {
    this.setState({error: null});
    if (data) {
      console.log(data);
      this.props.updateUrl(data);
    }
    else {
      if (this.state.legacyMode)
        this.props.updateUrl(null);
    }
  }
  handleError = err => {
    console.error(err)
    this.setState({error: err, legacyMode: true});
  }
  openImageDialog = () => {
    this.refs.qrReader1.openImageDialog();
  }
  render() {
    return (
      <div>
      <QrReader
        ref="qrReader1"
        onError={this.handleError}
        onScan={this.handleScan}
        style={this.props.style}
        legacyMode={this.state.legacyMode}
      />
      {this.state.error && <p className="text-red-500 text-center text-md mt-2">{this.state.error.message}</p>}
      {this.state.legacyMode && 
        <div className="text-center mt-2">
        <Button
          size="middle"
          type="primary"
          className="bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64"
          shape="round"
          onClick={this.openImageDialog}
        >
          Upload QR code
        </Button>
        </div>}
      </div>
    )
  }
}

export default QRScanner;
