import * as React from "react";
import { Spin } from "antd";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./authProvider";

export default function PrivateRoute({
  component: Component,
  path,
  history,
  ...rest
}) {
  const authContext = React.useContext(AuthContext);
  const [authStatus, setAuthStatus] = React.useState(authContext.status);

  React.useEffect(() => {
    (function() {
      setAuthStatus(authContext.status);
    })();
  }, [authContext.status]);

  console.log(authStatus);
  
  if(authStatus === "authenticated" || authStatus === "authorising") return <div className="center-div-on-screen"><Spin size="large"/></div>;
  return (
    <AuthContext.Consumer>
      {authState => {
        return (
          <Route
            path={path}
            {...rest}
            render={routeProps =>
              !!authState.user ? (
                authState.profileIncomplete && !path.includes("/complete-org-profile") ? (
                  <Redirect to="/complete-org-profile" />
                ) : (
                  <Component {...routeProps} />
                )
              ) : (
                <Redirect to="/" />
              )
            }
          />
        );
      }}
    </AuthContext.Consumer>
  );
}
