import React from "react";
import "antd/dist/antd.css";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";

import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache} from "apollo-boost";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
// import { InMemoryCache } from "apollo-cache-inmemory";

import Login from "./pages/login";
import CompleteProfile from "./pages/completeProfile";
import Profile from "./pages/profile";
import EditProfile from "./pages/editProfile";
import Status from "./pages/status";
import ForgotPassword from "./pages/forgotPassword";
import NotFound from "./pages/pageNotFound";

import PrivateRoute from "./components/privateRoute";
import { Header } from "./components/header";

import { AuthContext } from "./components/authProvider";
import bg_image from "./assets/bg_image.jpg";
import Qubento_logo from "./assets/QB_Logo_BW.png";
import "./styles/index.css";


export const DB_DOMAIN = "https://dbdroplet.indiahealth.com";

function App() {
  const authContext = React.useContext(AuthContext);
  const httpLink = new HttpLink({
    uri: `${DB_DOMAIN}/v1/graphql`,
  });
  
  const authLink = setContext((_, { headers }) => {
    const token = authContext.token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });
  
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      console.log("Error");
      console.log("graphQLError", graphQLErrors);
      console.log("networkError", networkError);
      if (graphQLErrors)
        graphQLErrors.map(async ({ message, extensions }) => {
          switch (extensions.code) {
            case "data-exception":
              break;
            case "invalid-headers":
              console.log("invalid-headers");
              window.location.href = "/";
              break;
            case "validation-failed":
              // props.history.push("/something-went-wrong");
              break;
            case "invalid-jwt":
              console.log("invalid-jwt");
              // Refresh token
              await authContext.fetchIdToken(true);
              window.location.href = "/";
              break;
            default:
              // default case
              console.log(extensions.code);
          }
        });
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // props.history.push("/network-error");
      }
    }
  );
  
  const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <div 
          style= {{ backgroundImage: `url(${bg_image})` }} 
          className="min-h-screen bg-fixed bg-cover md:bg-contain md:bg-repeat"
        >
          <div
            style={{
              maxWidth: "1600px",
              minHeight: "calc(100vh - 200px)"
            }}
            className="px-4 flex mx-auto"
          >
            <div className="w-full md:w-3/4 lg:w-1/2 pb-16 text-md shadow-3xl overflow-hidden mt-0 mx-auto rounded-b-lg bg-gradient-to-b from-white via-gray-100 to-transparent">
              <Header />
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                <PrivateRoute
                  path="/complete-org-profile"
                  exact
                  component={CompleteProfile}
                  history={history}
                />
                <PrivateRoute
                  path="/org-profile"
                  exact
                  component={Profile}
                  history={history}
                />
                <PrivateRoute
                  path="/edit-org-profile"
                  exact
                  component={EditProfile}
                  history={history}
                />
                <PrivateRoute
                  path="/scan"
                  exact
                  component={Status}
                  history={history}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
          </div>
          <div className="p-2 justify-center">
            <img
              src={Qubento_logo}
              alt="Qubento logo"
              className="p-1 m-auto"
            />
            <p className="text-white text-md text-center">Powered by Qubento</p>
          </div>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
