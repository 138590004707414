import React from "react";
import { AuthContext, myFirebase } from "./authProvider";
import { withRouter } from "react-router-dom";
import "../styles/index.css";
import "antd/dist/antd.css";
import { Link, Redirect } from "react-router-dom";
import IHF_Logo from "../assets/IHF_Logo_TransBG.png";
import { PoweroffOutlined, BankOutlined, ScanOutlined } from "@ant-design/icons";
import { Space, Tooltip, Button } from "antd";

export const Header = withRouter(props => <HeaderComponent {...props}/>);

class HeaderComponent extends React.Component {
    render() {
        return (
            <AuthContext.Consumer>
            {authState => {
                return (
                    <div className={!!authState.user && (authState.user.phoneNumber || (authState.user.email && authState.user.emailVerified)) ? 
                        "py-4 px-4 md:px-16 flex justify-between items-center" : 
                        "py-4 px-6 md:px-16 flex justify-center"}>
                        <Link to="/scan">
                            <div className="space-x-1 -ml-2">
                                <div className="inline-block align-middle">
                                    <img src={IHF_Logo} alt="logo" className="w-8 md:w-16" />
                                </div>
                                <div className="inline-block align-middle font-bold text-blue-800 hover:text-teal-600 text-xl md:text-2xl">
                                    Capital IHF
                                </div>
                            </div>
                        </Link>
                        <div className="space-x-1">
                            {!!authState.user && authState.user.email && authState.user.emailVerified &&
                                <div className="inline-block align-middle">
                                    <Space>
                                        <Tooltip title="Scan">
                                            <Button
                                                icon={<ScanOutlined className="text-lg md:text-2xl align-middle"/>}
                                                shape="circle"
                                                type="primary"
                                                className="bg-purple-700 hover:bg-purple-600 border-none"
                                                onClick={() => this.props.history.push("/scan")}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Organisation Profile">
                                            <Button
                                                icon={<BankOutlined className="text-lg md:text-2xl align-middle"/>}
                                                shape="circle"
                                                type="primary"
                                                className="bg-purple-700 hover:bg-purple-600 border-none"
                                                onClick={() => this.props.history.push("/org-profile")}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Signout">
                                            <Button
                                                icon={<PoweroffOutlined className="text-lg md:text-2xl align-middle"/>}
                                                shape="circle"
                                                type="primary"
                                                className="bg-purple-700 hover:bg-purple-600 border-none"
                                                onClick={() => {
                                                    myFirebase
                                                    .auth()
                                                    .signOut()
                                                    .then(function() {
                                                        // Sign-out successful.
                                                        console.log("Successful");
                                                        return (
                                                        <Redirect to="/" />);
                                                    })
                                                    .catch(function(error) {
                                                        console.log("error", error);
                                                        // An error happened.
                                                    });
                                                }}
                                            />
                                        </Tooltip>
                                    </Space>
                                </div>
                            }
                        </div>
                    </div>
                    // <p className="text-xl font-bold text-center text-blue-500">Organisation</p>
                );
            }}
            </AuthContext.Consumer>
        );
    }
}