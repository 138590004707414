import * as React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import { showNotification } from "../utils";
import axios from "axios";
import { DB_DOMAIN } from "../App";

export const AuthContext = React.createContext();

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDvCddpAQSeTJEmMVs3vcnNTra0QZwPJEc",
  authDomain: "capital-ihf-qb.firebaseapp.com",
  databaseURL: "https://capital-ihf-qb.firebaseio.com",
  projectId: "capital-ihf-qb",
  storageBucket: "capital-ihf-qb.appspot.com",
  messagingSenderId: "254907404993",
  appId: "1:254907404993:web:7d6b1bbb60f18f6e71fa40",
  measurementId: "G-ML38686YH8"
};

export const myFirebase = firebase.initializeApp(config);
// firebase.analytics();

export class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribeAuthListener = null;
    this.state = {
      status: "authenticated",
      user: null,
      token: null,
      role: "",
      profileIncomplete: true,
      setStatus: this.setStatus,
      setProfileIncomplete: this.setProfileIncomplete,
      fetchIdToken: this.fetchIdToken
    };
  }

  setStatus = (status) => {
    console.log("setStatus", status);
    this.setState({status: status});
  }

  setProfileIncomplete = (value) => {
    console.log("setProfileIncomplete", value);
    this.setState({profileIncomplete: value});
  }

  fetchIdToken = async (refresh) => {
    const user = this.state.user;
    if (user) {
      const idToken = await user.getIdToken(refresh);
      this.setState({token: idToken});
    }
  }

  authoriseUser = async () => {
    myFirebase.auth().onAuthStateChanged(async user => {
      if (user && (user.phoneNumber || (user.email && user.emailVerified))) {
        this.setState({status: "authorising"});
        const idToken = await user.getIdToken(true);
        const idTokenResult = await user.getIdTokenResult();
        const userClaims = idTokenResult.claims["https://hasura.io/jwt/claims"];
        console.log(userClaims);
        if (userClaims["x-hasura-default-role"] === "organisation")
        {
          let headers = {};
          headers.authorization = `Bearer ${idToken}`;

          const checkProfileCompletion = {
            query: `
                  query checkProfileCompletion {
                    user {
                      id
                      profile_completed
                    }
                  }
              `
          };

          // setTimeout(async () => {
          await axios
            .post(
              `${DB_DOMAIN}/v1/graphql`,
              checkProfileCompletion,
              {
                headers: headers
              }
            )
            .then(axiosRes => {
              console.log(axiosRes);
              if (axiosRes.data && axiosRes.data.data && axiosRes.data.data.user.length > 0) {
                if (axiosRes.data.data.user[0].profile_completed === false) {
                  this.setState({ user: user, token: idToken, role: "organisation", profileIncomplete: true, status: "authorised"});
                }
                else {
                  this.setState({ user: user, token: idToken, role: "organisation", profileIncomplete: false, status: "authorised"});
                }
              }
              else {
                myFirebase
                .auth()
                .signOut()
                .then(function() {
                  console.log("Successful logout");
                  showNotification("error", "Error!", "User not found. Please try logging in again");
                })
                .catch(function(error) {
                  console.log("error", error);
                });
              }
            })
            .catch(function() {
              myFirebase
              .auth()
              .signOut()
              .then(function() {
                console.log("Successful logout");
                showNotification("error", "Error!", "Something went wrong. Please try logging in again");
              })
              .catch(function(error) {
                console.log("error", error);
              });
            });
          // }, 2000);
        }
        else {
          myFirebase.auth().signOut()
          .then(function() {
            console.log("Successful logout");
            showNotification("error", "Error!", "Unauthorised");
          })
          .catch(function(error) {
            console.log("error", error);
          });
        }
      }
      else {
        this.setState({ user: null, token: null, role: "", status: "unauthenticated" });
      }
    });
  };

  componentDidMount = () => {
    this.unsubscribeAuthListener = this.authoriseUser();
  };

  componentWillUnmount = () => {
    if (this.unsubscribeAuthListener) this.unsubscribeAuthListener();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("comp", prevState, this.state);
  // }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {console.log(this.state)}
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
